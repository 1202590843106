<template>   
      <section>
        <nav v-if="isUserLoggedIn" class="relative px-6 py-6 bg-white">
          <div class="flex items-center">
            <ul v-if="isUserLoggedIn" class="hidden lg:flex lg:flex lg:items-center lg:w-auto lg:space-x-5">
              <li><router-link class="text-sm text-gray-400 hover:text-gray-500" to="/applications">Anwendungen</router-link></li>
              <li class="text-gray-300" v-if="false">
                <svg class="w-4 h-4 current-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                </svg>
              </li>
              <li v-if="false"><router-link class="text-sm text-gray-400 hover:text-gray-500" to="/faq">FAQs</router-link></li>
              <li v-if="false"><router-link class="text-sm text-gray-400 hover:text-gray-500" to="/profile">Profil</router-link></li>
              <li v-if="isUserAdmin" class="text-gray-300">
                <svg class="w-4 h-4 current-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                </svg>
              </li>
              <li v-if="isUserAdmin"><router-link class="text-sm text-red-400 hover:text-gray-500" to="/users">🛠 Benutzer 🛠</router-link></li>
              <li v-if="isUserAdmin" class="text-gray-300">
                <svg class="w-4 h-4 current-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                </svg>
              </li>
              <li v-if="isUserAdmin"><router-link class="text-sm text-red-400 hover:text-gray-500" to="/applications-admin">🛠 Anwendungen 🛠</router-link></li>
      
            </ul>
            <div class="lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-y-1/2 lg:-translate-x-1/2">
              <a class="text-3xl font-bold leading-none" href="#">
                <img class="h-12" src="atis-assets/logo/atis/atis-mono-black.svg" alt="" width="auto">
              </a>
            </div>
            <!--
            <a class="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold rounded-l-xl rounded-t-xl transition duration-200" href="#">Login</a><a class="hidden lg:inline-block py-2 px-6 bg-sky-500 hover:bg-sky-600 text-sm text-white font-bold rounded-l-xl rounded-t-xl transition duration-200" href="#">Sign up</a>
            -->
            <button v-if="!isUserLoggedIn && currentRoute.name != 'Login'" @click="goToLoginPage()" class="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold rounded-l-xl rounded-t-xl transition duration-200">Login</button>
            <button v-if="isUserLoggedIn" @click="logout()" class="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold rounded-l-xl rounded-t-xl transition duration-200">Ausloggen</button>
            <div class="lg:hidden ml-auto">
              <button class="navbar-burger flex items-center text-sky-600 p-3">
                <svg class="block h-4 w-4 fill-current" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Mobile menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
              </button>
            </div>
          </div>
        </nav>
        <div v-if="!connected" class="bg-red-600 h-10 leading-relaxed text-white flex flex-wrap items-center justify-center">
        <b>Keine Internetverbindung</b>
        </div>
        <div class="hidden navbar-menu relative z-50">
          <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div class="flex items-center mb-8">
              <a class="mr-auto text-3xl font-bold leading-none" href="#">
                <img class="h-10" src="atis-assets/logo/atis/atis-mono-black.svg" alt="" width="auto">
              </a>
              <button class="navbar-close">
                <svg class="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div>
              <ul v-if="isUserLoggedIn">
                <li class="mb-1"><router-link class="block p-4 text-sm font-semibold text-gray-400 hover:bg-sky-50 hover:text-sky-600 rounded" to="/applications">Anwendungen</router-link></li>
                <li v-if="false" class="mb-1"><router-link class="block p-4 text-sm font-semibold text-gray-400 hover:bg-sky-50 hover:text-sky-600 rounded" to="/profile">Profil</router-link></li>
              </ul>
            </div>
            <div class="mt-auto">
              <!--
              <div class="pt-6"><a class="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-l-xl rounded-t-xl" href="#">Login</a><a class="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-sky-600 hover:bg-sky-700 rounded-l-xl rounded-t-xl" href="#">Sign Up</a></div>
              -->
              <div v-if="!isUserLoggedIn" @click="goToLoginPage()" class="pt-6"><a class="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-l-xl rounded-t-xl">Einloggen</a></div>
              <div v-if="isUserLoggedIn" @click="logout()" class="pt-6"><a class="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-l-xl rounded-t-xl">Ausloggen</a></div>
            </div>
          </nav>
        </div>
      </section>


  <router-view/>

  <section class="bg-gray-50">
  <div class="flex w-full">
    <div class="w-1/3 flex">
      <div class="w-1/3 py-1 bg-sky-400"></div>
      <div class="w-1/3 py-1 bg-sky-500"></div>
      <div class="w-1/3 py-1 bg-sky-600"></div>
    </div>
    <div class="w-1/3 flex">
      <div class="w-1/3 py-1 bg-sky-400"></div>
      <div class="w-1/3 py-1 bg-sky-500"></div>
      <div class="w-1/3 py-1 bg-sky-600"></div>
    </div>
    <div class="w-1/3 flex">
      <div class="w-1/3 py-1 bg-sky-400"></div>
      <div class="w-1/3 py-1 bg-sky-500"></div>
      <div class="w-1/3 py-1 bg-sky-600"></div>
    </div>
  </div>
  <div class="container mx-auto px-4">
    <div class="pt-10 pb-5">
      <div class="relative lg:pb-8 mb-8 flex flex-wrap lg:border-b lg:border-gray-300">
        <div class="lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 mb-6 lg:mb-0 mx-auto"><a class="mr-6 text-sm hover:text-gray-500" href="https://www.clubinone.com/impressum" target="_blank">Impressum</a><a class="mr-6 text-sm hover:text-gray-500" href="https://www.smartup.de/agb.html" target="_blank">AGB</a><a class="mr-6 text-sm hover:text-gray-500" href="https://www.clubinone.com/datenschutz" target="_blank">Datenschutzhinweis</a><a class="text-sm hover:text-gray-500" href="https://support.clubinone.de/" target="_blank">Support</a></div>
      </div>
    </div>
  </div>
</section>
</template>

<style>
</style>

<script>
export default {
  name: 'App',
  data () {
    return {
      applications: null,
      filteredApplications: this.applications,
      showModal: false,
      lastLaunchedApplication: null,
      searchTerm: null,
      currentRoute: this.$router.currentRoute,
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    logout() {
      this.$store.commit('removeToken')
      this.$router.push({ name: 'Login' })
    },
    goToLoginPage() {
      this.$router.push({ name: 'Login' })
    },
  },
  computed: {
      connected() {
        return this.$store.getters['connected']
      },
      isUserLoggedIn() {
        return this.$store.getters.isUserLoggedIn
      },
      isUserAdmin() {
        if (this.$store.getters.user && this.$store.getters.user.admin === true) return true
        return false
      }
  },
  created () {
    document.title = "CLUB IN ONE Web Zugang"
    window.addEventListener('offline', () => {
      this.$store.dispatch('setConnected', false)

      let localStorageItem = JSON.parse(localStorage.getItem('offlineHistory'))
      if (!localStorageItem) localStorageItem = []
      localStorageItem.push({ event: 'offline', time: (new Date()).toISOString()})
      localStorage.setItem('offlineHistory', JSON.stringify(localStorageItem))
    })
    window.addEventListener('online', () => {
      this.$store.dispatch('setConnected', true)

      let localStorageItem = JSON.parse(localStorage.getItem('offlineHistory'))
      if (!localStorageItem) localStorageItem = []
      localStorageItem.push({ event: 'online', time: (new Date()).toISOString()})
      localStorage.setItem('offlineHistory', JSON.stringify(localStorageItem))
    })
  }
}


// Burger menus
document.addEventListener('DOMContentLoaded', function() {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (var k = 0; k < close.length; k++) {
            close[k].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (var l = 0; l < backdrop.length; l++) {
            backdrop[l].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
});
</script>

