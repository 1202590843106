import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import VueFinalModal from 'vue-final-modal'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import { createStore } from 'vuex'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

// GET RID OF DAYJS
dayjs.extend(calendar)
dayjs.extend(LocalizedFormat)

// OPTIMIZE STORE


axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('token')
    if (token) {
        config.headers = {
            'Authorization': `Bearer ${token}`
        }
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  }, function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      // TEST THIS
      store.commit('removeToken')
      router.push({ name: 'Login' })
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

  const store = createStore({
    state () {
      return {
        token: localStorage.getItem('token'),
        connected: navigator.onLine,
      }
    },
    mutations: {
      setToken (state, token) {
        state.token = token
        localStorage.setItem('token', token)
      },
      removeToken (state) {
        state.token = null
        localStorage.removeItem('token')
      },
      'SET_CONNECTED' (state, payload) {
        state.connected = payload
      },
    },
    getters: {
      user (state) {
        return jwt.decode(state.token)
      },
      isUserLoggedIn (state) {
        if (!state.token) return false
        return true
      },
      connected: state => state.connected,
    },
    actions: {
      setConnected ({ commit }, payload) {
        commit('SET_CONNECTED', payload)
      },
    }
  })

const app = createApp(App)
.use(router)
.use(store)
.use(VueFinalModal())

app.config.globalProperties.$dayjs = dayjs

app.mount('#app')